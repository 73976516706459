let timelineData = [
  {
    id: 1,
    title: "Cozy Cub CopBack",
    description:
      "We will be buying back 20 of our adorable cubs over a period of seven days beginning New Years Day. The cubs purchased will be placed in a publicly visible wallet and will be held for at least 30 days. Re-list will start at 1 SOL or higher. See Discord for wallet address.",
    buttonText: "Learn More",
    /* date: "25%", */
    icon: "snowflake",
  },
  {
    id: 2,
    title: "Cozy Club Merch",
    description:
      "Feel as cozy as your cub with stylish cozy apparel that brings your NFT to the spotlight of the normie world. ",
    buttonText: "Learn More",
    /* date: "50%", */
    icon: "snowflake",
  },
  {
    id: 3,
    title: "Leaderboard Giveaway",
    description:
      "Apart from other collabs and giveaways, a unique 1 of 1 cub designed by Grandpa will be given to our top holder. To check your position you can view HowRare.is holders section. The dates and cub will be revealed later in January 2022. Additional benefits to our top 10 holders will also be announced. ",
    buttonText: "Learn More",
    /* date: "75%", */
    icon: "snowflake",
  },
  {
    id: 4,
    title: "Cozy Cup: World Tour",
    description:
      "Cozy Cubs Play-to-Earn Multiplayer Kart Racer is in development. Our first promo is live showing off in engine footage. Join Discord to stay updated.",
    buttonText: "Learn More",
    /* date: "100%", */
    icon: "snowflake",
  },
  {
    id: 5,
    title: "Snowball Fight",
    description:
      "You have seen the teaser. This is a special part of the project that is in the works and something we are very excited for. Stay updated on twitter and our discord for more information on this game.",
    buttonText: "Learn More",
    /* date: "100%", */
    icon: "snowflake",
  },
  {
    id: 6,
    title: "Much More to come",
    description:
      "The future of Cozy Cubs is bright and full of promise. We believe the project and community is unique. We will continue to push forward with our ideas and future suggestions from the community to implement this vision. We are Cozy! We are Cute! #TooCuteToFail  ",
    buttonText: "Learn More",
    /* date: "100%", */
    icon: "snowflake",
  },
];

export default timelineData;
