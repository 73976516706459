import React from "react";
import sqblue from "../../../../images/bluesquiggle.png";
import sqgreen from "../../../../images/greensquiggle.png";
import {
  SquiggleBlue,
  SquiggleGreen,
  SquiggleTopContainer,
} from "./SqElements";

const Squiggle = () => {
  return (
    <SquiggleTopContainer>
      <SquiggleBlue src={sqblue} />
      <SquiggleGreen src={sqgreen} />
    </SquiggleTopContainer>
  );
};

export default Squiggle;
