import styled from "styled-components";

export const SquiggleTopContainer = styled.div`
  position: relative;
  top: -12vh;
  height: 325px;
  display: flex;
  align-items: flex-start;
  min-width: 100vw;

  @media screen and (min-width: 2000px) {
    top: 0px;
    height: 500px;
  }
`;

export const SquiggleBlue = styled.img`
  position: absolute;
  width: 100vw;
  height: 299px;
  top: 0;
  left: 0;
  object-fit: cover;

  @media screen and (min-width: 2000px) {
    height: auto;
  }
`;

export const SquiggleGreen = styled.img`
  position: absolute;
  width: 100vw;
  height: 299px;
  top: 32px;
  object-fit: cover;

  @media screen and (min-width: 2000px) {
    height: auto;
  }
`;
