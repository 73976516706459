import styled from "styled-components";

export const PageContainer = styled.div`
  position: relative;

  .custom-shape-divider-top-1635825610 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .custom-shape-divider-top-1635825610 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 99px;
    transform: rotateY(180deg);
  }

  .custom-shape-divider-top-1635825610 .shape-fill {
    fill: #dcebe9;
  }
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 20vh;
`;

export const Title = styled.h1`
  font-family: "AgentOrange";
  color: white;
  -webkit-text-stroke: 4px;
  font-size: clamp(40px, 10vw, 100px);
`;

export const TimeLineContainer = styled.div`
  position: relative;
  top: -10vh;
`;
