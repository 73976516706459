import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import questions from "./Data";
import Question from "./Questions";
function FAQSection() {
  return (
    <FaqStyled>
      <InnerLayout>
        <TitleContainer>
          <Title>FAQ</Title>
        </TitleContainer>
        <Fade left>
          <div className="questions-con">
            {questions.map((q) => {
              return <Question key={q.id} {...q} />;
            })}
          </div>
        </Fade>
      </InnerLayout>
    </FaqStyled>
  );
}

const InnerLayout = styled.section`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
`;

const TitleContainer = styled.div`
  text-align: center;
  position: relative;
  margin-top: 24px;
`;

const Title = styled.h1`
  font-family: "AgentOrange";
  color: white;
  -webkit-text-stroke: 4px;
  font-size: clamp(40px, 10vw, 100px);
`;

const FaqStyled = styled.section`
  position: relative;

  .c-para {
    width: 60%;
    margin: 0 auto;
  }
  .questions-con {
    padding-top: 6rem;

    width: 75%;
  }
`;

export default FAQSection;
