const questions = [
  {
    id: 1,
    title: "What is the total supply?",
    description: "There are 4000 Cozy Cubs.",
  },
  {
    id: 2,
    title: "What is the mint price?",
    description: "The mint price was .1 SOL.",
  },
  {
    id: 3,
    title: "When will Cozy Cubs launch?",
    description: "Mint went live on Dec 21st at Midnight.",
  },
  {
    id: 4,
    title: "Still have questions?",
    description:
      "Hop in the Discord to ask any further questions about Cozy Cubs or to just hang out.",
  },
];

export default questions;
