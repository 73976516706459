import styled from "styled-components";

//main container for video and text does not include carousel, relative used to allow normal document flow in case of navbar above.
export const HeroPage = styled.div`
  position: relative;
  height: 75vh;
  width: 100vw;
`;

//container for background video, absolute used to remove child (video) from document flow to allow Hero content over top.
export const VideoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

//height is 75vh to allow the sky to fill more of the screen and make a seamless transition for carousel
export const VideoBg = styled.video`
  object-fit: fill;
  width: 100vw;
  height: 75vh;
`;

export const HeroContent = styled.div`
  position: relative;
  top: -15px;
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  font-family: "AgentOrange";
  color: white;
  white-space: nowrap;
  font-size: clamp(3rem, 7vw, 7vw);
  -webkit-text-stroke: 4px;
  letter-spacing: -5px;
  padding-bottom: 5vh;
`;

export const Text = styled.span`
  font-family: "BwNistaGeometricDEMO-Regular";
  font-weight: 400;
  width: 75vw;
  color: white;
  font-size: clamp(1rem, calc(0.25rem + 1vw), 2rem);
  line-height: 200%;
  text-align: center;
  letter-spacing: 1.44px;
  padding-bottom: 10vh;
`;

export const MintContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  height: 70px;
  border-radius: 22px;
  text-align: center;
  background-color: rgba(64, 192, 239, 1);
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`;

export const MintText = styled.span`
  font-family: "omnes-pro", sans-serif;
  font-weight: 600;
  color: white;
  font-size: 33px;
  text-align: center;
  letter-spacing: 1.32px;
  white-space: nowrap;
`;
