import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100vw;
  position: relative;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  font-family: "AgentOrange";
  color: white;
  -webkit-text-stroke: 4px;
  font-size: clamp(40px, 10vw, 100px);
`;

export const CardContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  margin-top: 125px;
  margin-bottom: 125px;

  @media screen and (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 375px;
  width: 300px;
`;

export const CardImg = styled.img`
  object-fit: contain;
  height: 300px;
  width: 100%;
`;

export const CardBottom = styled.div`
  display: flex;
  height: 75px;
  width: 100%;
  align-items: center;
  background-color: rgb(18 65 90);
  justify-content: flex-start;
`;

export const SocialIcon = styled.div`
  height: 50px;
  width: 50px;
  margin-left: 20px;
  cursor: pointer;
`;

export const TwLogo = styled.img`
  height: 100%;
  width: 100%;
`;

export const TeamTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 35px;
`;

export const TeamName = styled.h1`
  font-family: "omnes-pro";
  font-weight: 600;
  color: white;
  font-size: 24px;
`;

export const TeamTitle = styled.h3`
  font-family: "omnes-pro";
  font-weight: 200;
  font-size: 24px;
  color: white;
`;
