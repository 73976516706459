import React from "react";
import { FaTwitter, FaDiscord } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  WebsiteRights,
} from "./FooterElements";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialLogo>Cozy Cubs</SocialLogo>
        <WebsiteRights>
          Cozy Cubs 2023 All rights reserved by Cozy Cubs Team
        </WebsiteRights>
        <SocialIcons>
          <SocialIconLink
            href="//www.twitter.com/CozyCubsNFT"
            target="_blank"
            aria-label="Twitter"
          >
            <FaTwitter />
          </SocialIconLink>
          <SocialIconLink
            href="https://discord.gg/ETTrnrKTgB"
            target="_blank"
            aria-label="Discord"
          >
            <FaDiscord />
          </SocialIconLink>
        </SocialIcons>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
