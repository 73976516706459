import styled from "styled-components";

export const ContentContainer = styled.div`
  position: relative;
  height: auto;
`;

export const ContentBg = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  top: 0;
`;

export const BgVideo = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  video::-webkit-media-controls {
    display: none !important;
  }
`;
