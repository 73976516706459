import React from "react";
import Logo from "../../../images/CCLogoIgloo.png";
import {
  NavBtn,
  NavContainer,
  NavLogo,
  NavTitle,
  NavTitleContainer,
  SocialContainer,
} from "./NavElements";
import { SocialIcon } from "react-social-icons";
import { Wallet } from "../../SolanaWallet/Setup";

const Nav = () => {
  return (
    <NavContainer>
      <NavLogo src={Logo} alt="/" />
      <NavTitleContainer>
        <NavTitle>Join our Twitter and Discord for new updates</NavTitle>
        <SocialContainer>
          <SocialIcon
            url="https://discord.gg/ETTrnrKTgB"
            fgColor="#fff"
            style={{ height: 35, width: 35 }}
          />
        </SocialContainer>
        <SocialContainer>
          <SocialIcon
            url="https://twitter.com/CozyCubsNFT"
            fgColor="#fff"
            style={{ height: 35, width: 35 }}
          />
        </SocialContainer>
      </NavTitleContainer>
      <NavBtn>{/* <Wallet /> */}</NavBtn>
    </NavContainer>
  );
};

export default Nav;
