import React, { useRef } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import mint from "../../images/Modal/modal.png";
import CandymachineApp from "./CandyMachine/CandyMachine/App";

const Background = styled.div`
  width: 100%;
  height: 150vh;
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
`;
const ModalWrapper = styled.div`
  width: 1000px;
  height: 600px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 15;
  border-radius: 10px;
`;

const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: #000;
  @media screen and (max-width: 500px) {
    display: none;
  }
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  font-family: "omnes-pro", sans-serif;

  p {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  button {
    /* padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: none; */
  }
`;

const InstructionContainer = styled.div`
  line-height: 1;
  text-align: left;
  margin-top: 1rem;
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;

export const MintModal = ({ showModal, setShowModal }) => {
  const modalRef = useRef();

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? "trasnlateY(10%)" : "translateY(-100%)",
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  return (
    <>
      {showModal ? (
        <Background ref={modalRef} onClick={closeModal}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <ModalImg src={mint} alt="camera" />
              <ModalContent>
                <h1>How to Buy Your Cozy Cubs.</h1>
                <h2>Mint Sold out at 0.1 SOL!</h2>
                <InstructionContainer>
                  <p> First set up a Phantom Wallet</p>
                  <a href="https://phantom.app/">Phantom Wallet Extension</a>
                  <p> Buy your cubs on the secondary market here: </p>
                  <a href="https://magiceden.io/marketplace/cozy_cubs">
                    {" "}
                    Magic Eden{" "}
                  </a>

                  <p>Check your cubs rarity rank on MoonRank here:</p>
                  <a href="https://moonrank.app/collection/cozy_cubs">
                    MoonRank
                  </a>
                  <p>Check out the first promo of Cozy Cup: World Tour</p>
                  <a href="https://twitter.com/CozyCubsNFT/status/1521512517822033920">
                    Cozy Cup: World Tour
                  </a>
                </InstructionContainer>
                {/* <CandymachineApp /> */}
              </ModalContent>
              <CloseModalButton
                aria-label="Close modal"
                onClick={() => setShowModal((prev) => !prev)}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};
