import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import img1 from "../../../../images/ImgCarousel/slide1.png";
import img2 from "../../../../images/ImgCarousel/slide2.png";
import img3 from "../../../../images/ImgCarousel/slide3.png";
import img4 from "../../../../images/ImgCarousel/slide4.png";
import img5 from "../../../../images/ImgCarousel/slide5.png";
import img6 from "../../../../images/ImgCarousel/slide6.png";
import img7 from "../../../../images/ImgCarousel/slide7.png";
import img8 from "../../../../images/ImgCarousel/slide8.png";
import img9 from "../../../../images/ImgCarousel/slide9.png";

const Container = styled.div`
  width: 100vw;
  height: 300px;
  background-color: rgba(224, 238, 232, 1);
  position: relative;

  @media screen and (min-width: 1921px) {
    height: auto;
  }
`;

const Slide = styled.img`
  height: 100%;
  width: 90%;
  border-radius: 30px;
`;

export default class AutoPlay extends Component {
  render() {
    const settings = {
      accessibility: false,
      arrows: false,
      autoplaySpeed: 1,
      autoplay: true,
      centerPadding: "50px",
      dots: false,
      draggable: false,
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: 6,
      speed: 5000,
      swipe: false,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Container>
        <Slider {...settings}>
          <div>
            <Slide src={img1} />
          </div>
          <div>
            <Slide src={img2} />
          </div>
          <div>
            <Slide src={img3} />
          </div>
          <div>
            <Slide src={img4} />
          </div>
          <div>
            <Slide src={img5} />
          </div>
          <div>
            <Slide src={img6} />
          </div>
          <div>
            <Slide src={img7} />
          </div>
          <div>
            <Slide src={img8} />
          </div>
          <div>
            <Slide src={img9} />
          </div>
        </Slider>
      </Container>
    );
  }
}
