import "./App.css";

import Hero from "./components/Hero/Hero";
import Nav from "./components/Content/NavBar/Nav";
import Content from "./components/Content/Content";

function App() {
  return (
    <>
      <Nav />
      <Hero />
      <Content />
    </>
  );
}

export default App;
