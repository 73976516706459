import React, { useState } from "react";
import styled from "styled-components";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

function Question({ title, description }) {
  const [toggle, setToggle] = useState(false);

  const btnToggler = () => {
    setToggle(!toggle);
  };

  return (
    <QuestionStyled>
      <div className="q-con">
        <div className="toggle-title">
          <h4>{title}</h4>
          <button onClick={btnToggler}>
            {toggle ? (
              <AiOutlineMinus size={28} />
            ) : (
              <AiOutlinePlus size={28} />
            )}
          </button>
        </div>
        {toggle && <p>{description}</p>}
      </div>
    </QuestionStyled>
  );
}

const QuestionStyled = styled.div`
  background-color: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(5px);
  margin: 1rem 0;
  padding: 1.4rem 1rem;
  justify-content: center;
  border-radius: 24px;
  box-shadow: 0px 25px 50px rgba(22, 25, 79, 0.05);

  p {
    font-family: "BwNistaGeometricDEMO-Regular";
    font-weight: 200;
    color: white;
    font-size: 1.3rem;
    padding-top: 10px;
    line-height: 3rem;
    text-align: left;
  }
  h4 {
    transition: all 0.4s ease-in-out;

    font-family: "omnes-pro";
    font-weight: 700;
    color: white;
    font-size: 1.5rem;
  }
  .toggle-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.4s ease-in-out;

    button {
      background: transparent;
      outline: none;
      cursor: pointer;
      border: none;
    }
  }
`;

export default Question;
