import React from "react";
import Fade from "react-reveal/Fade";
import team1 from "../../../../images/TeamImg/pman.png";
import team2 from "../../../../images/TeamImg/Chefs.png";
import team3 from "../../../../images/TeamImg/Grumpy.png";
import team4 from "../../../../images/TeamImg/Grandpa.png";
import tw from "../../../../images/twlogo.svg";
import {
  Card,
  CardBottom,
  CardContainer,
  CardImg,
  PageContainer,
  SocialIcon,
  TeamTextContainer,
  Title,
  TitleContainer,
  TwLogo,
  TeamName,
  TeamTitle,
} from "./TeamElements";

const Team = () => {
  return (
    <PageContainer>
      <TitleContainer>
        <Title>TEAM</Title>
      </TitleContainer>
      <Fade right>
        <CardContainer>
          <Card>
            <CardImg src={team1} />
            <CardBottom>
              <a href="//www.twitter.com/papi_pierre_">
                <SocialIcon>
                  <TwLogo src={tw} />
                </SocialIcon>
              </a>
              <TeamTextContainer>
                <TeamName>Papa_P</TeamName>
                <TeamTitle>Founder</TeamTitle>
              </TeamTextContainer>
            </CardBottom>
          </Card>
          <Card>
            <CardImg src={team3} />
            <CardBottom>
              <a href="//www.twitter.com/Grumpy_xbt">
                <SocialIcon>
                  <TwLogo src={tw} />
                </SocialIcon>
              </a>
              <TeamTextContainer>
                <TeamName>Grumpy</TeamName>
                <TeamTitle>Lead Developer</TeamTitle>
              </TeamTextContainer>
            </CardBottom>
          </Card>
          <Card>
            <CardImg src={team4} />
            <CardBottom>
              <a href="//www.twitter.com/CozyCubsNFT">
                <SocialIcon>
                  <TwLogo src={tw} />
                </SocialIcon>
              </a>
              <TeamTextContainer>
                <TeamName>Grandpa</TeamName>
                <TeamTitle>Lead Developer</TeamTitle>
              </TeamTextContainer>
            </CardBottom>
          </Card>
          <Card>
            <CardImg src={team2} />
            <CardBottom>
              <a href="//www.twitter.com/chefshwess">
                <SocialIcon>
                  <TwLogo src={tw} />
                </SocialIcon>
              </a>
              <TeamTextContainer>
                <TeamName>Chefshwess</TeamName>
                <TeamTitle>Manager</TeamTitle>
              </TeamTextContainer>
            </CardBottom>
          </Card>
        </CardContainer>
      </Fade>
    </PageContainer>
  );
};

export default Team;
