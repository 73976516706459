import React, { useState } from "react";
import NLights from "../../videos/HeroBG.mp4";
import { MintModal } from "../Mint/MintModal";
import {
  HeroContent,
  HeroPage,
  MintContainer,
  MintText,
  Text,
  Title,
  VideoBg,
  VideoContainer,
} from "./HeroElements";

const Hero = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <HeroPage>
      <VideoContainer>
        <VideoBg
          autoPlay
          playsInline
          loop
          muted
          src={NLights}
          type="video/mp4"
        />
      </VideoContainer>
      <HeroContent>
        <Title>COZY CUBS</Title>
        <Text>
          Cozy Cubs is a collection of 4000 Polar Bear
          <br />
          Cubs that took the polar plunge on the
          <br />
          Solana blockchain.
        </Text>
        <MintContainer onClick={openModal}>
          <MintText>Buy Here!</MintText>
        </MintContainer>
        <MintModal showModal={showModal} setShowModal={setShowModal} />
      </HeroContent>
    </HeroPage>
  );
};

export default Hero;
