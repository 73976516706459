import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
  mintMultipleToken,
} from "./candy-machine";
import { WalletDisconnectButton } from "@solana/wallet-adapter-react-ui";
import { sleep } from "./utility";
import toast, { Toaster } from "react-hot-toast";
import Incrementor from "../components/MultiMint";

const ConnectButton = styled(WalletDialogButton)`
  background: #01bf71;
  margin-left: 10px !important;
`;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)`
  border-radius: 50px;
  background: #01bf71 !important;
  white-space: nowrap;
  padding: 12px 30px;
  color: #010606;
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
  }
`; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const [mintPrice, setMintPrice] = useState(0);
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
        mintPrice,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);
      setMintPrice(mintPrice);
      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet. Check FAQ for time.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };
  const onMintMultiple = async (quantity: number) => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program && wallet.publicKey) {
        const oldBalance = await props.connection.getBalance(wallet?.publicKey);

        const signedTransactions: any = await mintMultipleToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury,
          quantity
        );

        const promiseArray = [];

        for (let index = 0; index < signedTransactions.length; index++) {
          const tx = signedTransactions[index];
          promiseArray.push(
            awaitTransactionSignatureConfirmation(
              tx,
              props.txTimeout,
              props.connection,
              "singleGossip",
              true
            )
          );
        }
        const allTransactionsResult = await Promise.all(promiseArray);
        let totalSuccess = 0;
        let totalFailure = 0;

        for (let index = 0; index < allTransactionsResult.length; index++) {
          const transactionStatus = allTransactionsResult[index];
          if (!transactionStatus?.err) {
            totalSuccess += 1;
          } else {
            totalFailure += 1;
          }
        }

        let newBalance = await props.connection.getBalance(wallet?.publicKey);

        while (oldBalance === newBalance) {
          await sleep(1000);
          newBalance = await props.connection.getBalance(wallet?.publicKey);
        }

        if (totalSuccess) {
          toast.success(
            `Congratulations! ${totalSuccess} mints succeeded! Your NFT's should appear in your wallet soon :)`,
            { duration: 6000, position: "bottom-center" }
          );
        }

        if (totalFailure) {
          toast.error(
            `Some mints failed! ${totalFailure} mints failed! Check on your wallet :(`,
            { duration: 6000, position: "bottom-center" }
          );
        }
      }
    } catch (error: any) {
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet. Check FAQ for time.`;
        }
      }
      toast.error(message);
    } finally {
      if (wallet?.publicKey) {
        const balance = await props.connection.getBalance(wallet?.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  //Multi Mint Component and Functions and Styled Components
  const Mintmulti = styled.button`
    width: 125px;
    font-size: 16px;
    font-weight: 600;
    background-image: linear-gradient(
      to right,
      #25aae1,
      #40e495,
      #30dd8a,
      #2bb673
    );
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 50px;
    text-align: center;
    border: none;
    background-size: 300% 100%;

    border-radius: 15px;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    &:hover {
      background-position: 100% 0;
      moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }
  `;
  const Holder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* -webkit-box-shadow: 7px 5px 11px -1px rgba(11, 33, 51, 0.92);
    box-shadow: 7px 5px 11px -1px rgba(11, 33, 51, 0.92); */
  `;

  /* const Left = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e88e5;
    width: 120px;
    height: 180px;
  `; */

  const Right = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #1e88e5; */
    background: transparent;
    width: 220px;
  `;

  /* const Display = styled.div`
    color: #fff;
    display: flex;
    justify-content: center;
    font-size: 3rem;
  `; */

  const min = 2;
  const max = 4;

  const MintMany = () => {
    const [mintCount, setMintCount] = useState(4);
    const [value, setValue] = useState(min);
    return (
      <>
        <Holder>
          <Mintmulti
            onClick={() => onMintMultiple(value)}
            /* onClick={() => onMintMultiple(mintCount)} */
            disabled={isMinting}
            /* className="px-4 py-2 mx-auto font-bold text-white transition-opacity rounded-lg hover:opacity-70 bg-gradient-to-br from-green-300 via-blue-500 to-purple-600" */
          >
            {isMinting ? "loading" : `Mint ${value}`}
            {/* {isMinting ? "loading" : `mint ${mintCount}`} */}
          </Mintmulti>
          {/* <Left>
            <Display>{value}</Display>
          </Left> */}
          <Right>
            <Incrementor
              value={value}
              onChange={(v) => setValue(v)}
              min={min}
              max={max}
            />
          </Right>
        </Holder>
        {/*  <input
          disabled={isMinting}
          type="number"
          min={2}
          max={4}
          className="px-2 mx-auto mt-5 font-bold text-white bg-gray-500"
          value={mintCount}
          onChange={(e) => setMintCount((e.target as any).value)}
        />
        <p className="mx-auto mt-2">min 2; max 4;</p> */}
      </>
    );
  };

  return (
    <main>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          fontWeight: "700",
        }}
      >
        {wallet && (
          <p>Wallet: {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
        )}

        {wallet && <p> Mint Price: {mintPrice / LAMPORTS_PER_SOL} SOL </p>}

        {/* {wallet && <p>Balance: {(balance || 0).toLocaleString()} SOL</p>} */}

        {/* {wallet && <p>Total Available: {itemsAvailable}</p>} */}

        {/* {wallet && <p>Minted: {itemsRedeemed}</p>} */}

        {wallet && <p>Available to Mint: {itemsRemaining}</p>}
      </div>
      <MintContainer>
        {!wallet ? (
          <ConnectButton>
            {wallet ? "connected" : "Select Wallet"}
          </ConnectButton>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Mintmulti
                disabled={isSoldOut || isMinting || !isActive}
                onClick={onMint}
                variant="contained"
              >
                {isSoldOut ? (
                  "SOLD OUT"
                ) : isActive ? (
                  isMinting ? (
                    <CircularProgress />
                  ) : (
                    "Mint 1"
                  )
                ) : (
                  <Countdown
                    date={startDate}
                    onMount={({ completed }) => completed && setIsActive(true)}
                    onComplete={() => setIsActive(true)}
                    renderer={renderCounter}
                  />
                )}
              </Mintmulti>
              <MintMany />
              {/* <Mintmulti
              disabled={isSoldOut || isMinting || !isActive}
              onClick={() => onMintMultiple(5)}
            >
              {isSoldOut ? (
                "SOLD OUT"
              ) : isActive ? (
                isMinting ? (
                  <CircularProgress />
                ) : (
                  "MINT"
                )
              ) : (
                <Countdown
                  date={startDate}
                  onMount={({ completed }) => completed && setIsActive(true)}
                  onComplete={() => setIsActive(true)}
                  renderer={renderCounter}
                />
              )}
            </Mintmulti> */}
            </div>
            <div
              style={{
                marginLeft: "10px",
              }}
            >
              <WalletDisconnectButton />
            </div>
          </div>
        )}
      </MintContainer>
      <Toaster />
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
