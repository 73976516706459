import styled from "styled-components";

export const NavContainer = styled.div`
  background: rgba(0, 11, 57, 0.58);
  height: 8vh;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  padding: 0 10vw;
  align-items: center;
  z-index: 2;
`;

export const NavLogo = styled.img`
  height: 80px;
  width: 80px;
`;

export const NavTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavTitle = styled.span`
  font-family: "omnes-pro", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: white;
  text-decoration: underline;
  @media screen and (max-width: 749px) {
    display: none;
  }
`;

export const SocialContainer = styled.div`
  margin-left: 3px;
  margin-right: 3px;
`;
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
`;
