import React from "react";
import { BgVideo, ContentBg, ContentContainer } from "./ContentElements";
import Lights from "../../videos/bglights.mp4";
import AutoPlay from "./Body/Carousel/Carousel";
import TimeLineSection from "./Body/Timeline/Timeline";
import Team from "./Body/Team/Team";
import FAQSection from "./Body/FAQ/FAQContainer";
import Footer from "./Body/Footer/Footer";
import Snowfall from "react-snowfall";
import Hero from "../Hero/Hero";

const Content = () => {
  return (
    <ContentContainer>
      <ContentBg>
        <BgVideo
          autoPlay
          playsInline
          loop
          muted
          src={Lights}
          type="video/mp4"
        />
      </ContentBg>
      <Snowfall snowflakeCount={100} />
      <AutoPlay />
      <TimeLineSection />
      <Team />
      <FAQSection />
      <Footer />
    </ContentContainer>
  );
};

export default Content;
