import styled from "styled-components";

export const FooterContainer = styled.div`
  background-color: #101522;
  position: relative;
`;

export const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;
  max-width: 1100px;
  width: 100%;
  margin: 40px auto 0 auto;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled.div`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-family: "BwNistaGeometricDEMO-Regular";
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
  font-size: 0.8rem;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 150px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;
